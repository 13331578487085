
import { mapGetters } from 'vuex';

import actionsPlanMixin from '../mixins/actionsPlan';
import uiMixin from '../mixins/ui';

import Value from './value';

export default {
  name: 'ActionsPlanProgress',
  components: {
    Value,
  },
  mixins: [
    actionsPlanMixin,
    uiMixin,
  ],
  props: {
    message: {
      type: String,
      default: null,
    },
    isActionsPlan: {
      type: Boolean,
      default: false,
    },
    isPlain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('actionsUser', [
      'itemsCompletedVirtualDay',
    ]),
    itemsCompletedVirtualDayRecent() {
      const now = this.$store.state.now.toDate().getTime();

      return this.itemsCompletedVirtualDay.filter((i) => {
        const secondsSinceCompletion = Math.round((now - i.lastProgressAt.getTime()) / 1000);
        return secondsSinceCompletion < 3600;
      });
    },
    actionsPlanProgressBarValue() {
      return Math.max(0, 100 - this.progressPercent);
    },
    actionsPlanProgressStyle() {
      return {
        boxShadow: `0px 0px ${this.progress * 5}px ${this.progress * 1}px var(--sl-color-${this.actionsPlanVariant}-700)`,
      };
    },
    helpText() {
      let text = 'Complete your actions to make progress';

      if (this.isComplete) {
        text = 'Mission accomplished';
      } else if (this.progress >= 0.8) {
        text = 'Almost there';
      } else if (this.progress >= 0.5) {
        text = 'Good progress so far';
      } else if (this.isValueLowerThanExpected) {
        text = 'Add and complete actions to make progress!';
      } else if (this.isValueHigherThanExpected) {
        text = 'Ambitious';
      } else if (this.progress >= 0.25) {
        if (!this.$store.getters['conditions/hasActionsRepeatingVirtualDay']) {
          text = 'Try adding a habit by setting an action to repeat';
        } else if (!this.$store.getters['conditions/hasActionsWithTimeframeFuture']) {
          text = 'Plan ahead by scheduling an action for tomorrow';
        }
      }

      return text;
    },
    messageText() {
      return this.message ? this.message : this.helpText;
    },
    milestones() {
      return this.isDebugger
        ? this.$store.getters['conditions/actionsPlanMilestones'].filter(m => m.isReached)
        : [];
    },
  },
  methods: {
    handleViewActionsPlan() {
      if (!this.isActionsPlan) {
        this.$store.dispatch('ui/showModal', { name: 'actionsPlan' });
      }
    },
  },
};
