
import { mapGetters } from 'vuex';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import actionsPlanMixin from '../mixins/actionsPlan';
import uiMixin from '../mixins/ui';

import ActionsDataView from './actions-data-view';
import ActionsPlanProgress from './actions-plan-progress';

export default {
  name: 'ActionsPlan',
  components: {
    ActionsDataView,
    ActionsPlanProgress,
  },
  mixins: [
    actionsPlanMixin,
    uiMixin,
  ],
  computed: {
    ...mapGetters('actionsUser', [
      'itemsRepeatingOnVirtualDay',
    ]),
    ...mapGetters('actionsPlan', [
      'itemIds',
      'itemsOrdered',
      'itemsUpcomingOrdered',
    ]),
    itemsRepeatingOnVirtualDayIncomplete() {
      return this.itemsRepeatingOnVirtualDay
        .filter(i => !this.itemIds.includes(i.id) && !this.$store.getters['actionsUser/isItemComplete'](i));
    },
    hasActions() {
      return this.$store.state.actionsUser.all.length > 0;
    },
    cycleProps() {
      const map = [
        {
          width: '250px',
          height: '200px', // 4/5ths
          fontSize: '1.75rem',
        },
        {
          width: '200px',
          height: '160px',
          fontSize: '1.5rem',
        },
        {
          width: '100px',
          height: '80px',
          fontSize: '1rem',
        },
      ];

      return map[this.$store.state.actionsUser.all.length];
    },
    milestones() {
      return this.isDebugger
        ? this.$store.getters['conditions/actionsPlanMilestones']
        : [];
    },
  },
  mounted() {
    Haptics.impact({ style: ImpactStyle.Light });

    if (!this.hasActions) {
      this.$store.dispatch('ui/showModal', { name: 'actionsPlanCycle' });
    }
  },
  methods: {
    handleActionSelected(items, actionId) {
      // pass all items and set activeIndex to actionId index
      this.uiShowActionSelector(items.map(i => i.id), actionId);
    },
  },
};
